import { isMobile } from '@buzzfeed/bf-utils/lib/device';
import { isNews } from './isNews';
import { isOrHasQuiz } from './isOrHasQuiz';

const WORKFLOW_TAG_TOP_PLAYER = 'b_page_video_tests_top';
const WORKFLOW_TAG_DISABLE_BACKFILL_PLAYER = 'disable_backfill_player';

export const hasConatixVideo = buzz => {
  return !!buzz.sub_buzzes?.find(sb => sb.form === 'bfp' && sb?.format_name === 'connatix_video')
    || isPlayspaceEligible(buzz) || isElementsEligible(buzz);
};

export const isSickyVideoEligible = buzz => {
  return buzz.classification.edition !== 'Japan';
};

const hasTopVideoPlayerTag = buzz => {
  const workflow = (buzz?.laser_tags?.bf_content_description?.workflow || []);
  const topVidTag = workflow.filter(tag => tag.tag_name === WORKFLOW_TAG_TOP_PLAYER);
  return topVidTag.length > 0;
}

const hasDisableBackfillPlayerTag = buzz => {
  const workflow = (buzz?.laser_tags?.bf_content_description?.workflow || []);
  const backfillTag = workflow.some(tag => tag.tag_name === WORKFLOW_TAG_DISABLE_BACKFILL_PLAYER);
  return backfillTag;
};

export const isPlayspaceEligible = buzz => {
  return isNews(buzz) && !isOrHasQuiz(buzz) && !hasTopVideoPlayerTag(buzz) && !hasDisableBackfillPlayerTag(buzz);
}

export const isElementsEligible = buzz => {
  const isSensitive = buzz.flags.sensitive || false;
  const isDesktopWide = buzz.format.page_width === 'wide' && !isMobile;
  // no news, no quiz, no shopping, no advertiser post, no manual video embed at the top, no desktop wide, no Disable Backfill Player tag
  return !(isNews(buzz)
    || isOrHasQuiz(buzz)
    || buzz.isShopping
    || buzz.isAd
    || hasTopVideoPlayerTag(buzz)
    || isSensitive
    || isDesktopWide
    || hasDisableBackfillPlayerTag(buzz)
  );
};
