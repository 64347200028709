import stickyRegistry from '@buzzfeed/bf-utils/lib/sticky-registry';
import {
  sizes,
  programmaticSizes,
} from '@buzzfeed/adlib/dist/module/bindings/react';
import { CLUSTER } from '../../constants';
import { localization as l10n, userLocalization, getUserGeo } from '../localization';
import { isNews } from '../isNews';
import { hasConatixVideo, isSickyVideoEligible } from '../conatixVideo';

export const getBuzzTags = buzz => {
  const laserTagsTopic = buzz?.laser_tags?.bf_content_description?.topic || [];
  const laserTagsWorkflow = buzz?.laser_tags?.bf_content_description?.workflow || [];
  const laserTagsCommerceCollection = buzz?.laser_tags?.subbuzz?.commerce_collection || [];
  return [
    ...buzz.tags.filter(tag => !/^--/.test(tag)), // cms tags, excluding special metadata tags
    ...laserTagsTopic.map(tag => tag.tag_name),
    ...laserTagsWorkflow.map(tag => tag.tag_name),
    ...laserTagsCommerceCollection.map(tag => tag.tag_name),
    buzz.isCommentsPage ? 'pagetype_c' : 'pagetype_b',
  ].filter((tag, index, tags) => tags.indexOf(tag) === index);
};

/**
 *
 * @param {Object} buzz - full buzz object
 * @param {Function} getFeatureFlagValue - function returned from AbeagleContext hook
 * @param {Function} getExperimentValue - function returned from AbeagleContext hook
 * @param {Object} tracking - namespace for legacy tracking functions, see useLegacyTracking hook
 * @returns {Promise<*>}
 */
export async function getPageContext({ base_url, buzz, abeagle, tracking }) {
  const localization = {
    ...l10n(buzz),
  };
  const isBFN = isNews(buzz);
  const userCountry = getUserGeo().toLowerCase();
  const userLocale = userLocalization();
  const env = {
    // constant values
    adSizes: sizes,
    programmaticSizes,
    destination: isBFN ? ['bfnews', 'buzzfeednews'] : 'buzzfeed',
    isBFN,
    isE2ETest: false,
    isFeed: false,
    isFeedPage: false,
    isFeedpager: false,
    isHomePage: false,
    isNewsPost: false, // is it always so?
    isBFO: buzz.destination_name === 'buzzfeed',
    isBPage: true,
    isDev: CLUSTER === 'dev',
    isProd: CLUSTER === 'prod',
    pageFilter: null,
    pageFilters: {},
    pageMainFilter: null,
    type: CLUSTER,
    webRoot: base_url,

    // variable values
    allPageSections: buzz.all_classifications.sections,
    author: buzz.username,
    cmsTags: getBuzzTags(buzz),
    hasQuiz: buzz.is_quiz,
    hasConnatixVideo: hasConatixVideo(buzz) && isSickyVideoEligible(buzz),
    amazonNCA: buzz.isUS && buzz.isShopping,
    isAsIs: /^(As\/?Is)/i.test(buzz.classification.section),
    isPharmaceutical: buzz.username === 'emdserono',
    isWidePost: buzz.format.page_width === 'wide',
    laserTags: buzz?.laser_tags || {},
    localization,
    locale: userLocale.locale,
    localizationCountry: userLocale.country,
    userCountry,
    pageCategory: buzz.category,
    pageSection: buzz.classification.section,
    pageId: buzz.id,
    isAdPost() {
      return buzz.flags.ad === 1;
    },
  };

  return {
    env,
    abeagle,
    localization,
    // since adlib is loaded as a separate chunk
    // and `stickyRegistry`'s global state needs to be shared between the main chunk and adlib,
    // we're passing it as a dependency (instead of just importing in adlib)
    stickyRegistry,
    tracking,
  };
}
